import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import SimpleSection from "../components/sections/simpleSection/SimpleSection";
import BreadCrumps from "../ui/breadCrumps/BreadCrumps";
import { HelmetDatoCms } from "gatsby-source-datocms";

const About = ({ data }) => {
  return (
    <React.Fragment>
      <HelmetDatoCms seo={data.page.seo} favicon={data.site.favicon} />
      <Layout>
        <BreadCrumps title={data.page.sectiontitle} />
        <SimpleSection
          id="aboutpage"
          sectionTitle={data.page.sectiontitle}
          sectionTitleDesc={data.page.sectiondescription}
          sectiontitleBtnTxt=""
          sectiontitleBtnUrl={false}
          isBlackBg={false}
        >
          <div className="about-text-wrapper">
            <section className="html-section">
              <div
                key={`body`}
                dangerouslySetInnerHTML={{ __html: data.page.htmlcontent }}
              ></div>
            </section>
          </div>
        </SimpleSection>
      </Layout>
    </React.Fragment>
  );
};

export default About;

export const query = graphql`
  {
    site: datoCmsSite {
      favicon: faviconMetaTags {
        ...GatsbyDatoCmsFaviconMetaTags
      }
    }
    page: datoCmsAboutuspage {
      seo: seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      htmlcontent
      sectiontitle
      sectiondescription
    }
  }
`;
